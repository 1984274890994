import React, { useState, useContext, useRef, useEffect } from 'react';
import { userContext } from './../../context/UserContext';
import { Header } from '../../components/ui/Header';

import './styles/Reports.css';

export const Reports = () => {
  const { userData } = useContext(userContext);
  return (
    <div>
      <Header user={userData} />
      <div className='reports'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <iframe
                title='Fichajes_V3'
                width='1024'
                height='1060'
                src='https://app.powerbi.com/view?r=eyJrIjoiNTc1YzEyMDMtZTZhYi00Y2VkLTkwYTgtNWQ4NDdmMzJmNWQ0IiwidCI6IjYxN2Q1ZTA5LTY2MWYtNGVkYi1hNDRkLWU2NGRhZDljOTM1MyIsImMiOjl9'
                frameborder='0'
                allowFullScreen='true'
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
