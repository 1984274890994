import React, { useState, useEffect } from 'react';
import { addDoc, setDoc, collection, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import moment from 'moment';

export const NewCalendar = (props) => {
  const [data, setData] = useState([]);

  const [inputValue, setInputValue] = useState([
    {
      time: '08:30',
      color: '#198754',
    },
    {
      time: '07:00',
      color: '#0d6efd',
    },
  ]);

  const [activeInput, setActiveInput] = useState({
    time: '08:30',
    color: '#198754',
  });

  const name = props.calendar.name;
  const users = props.calendar.users;
  const variableTime = props.calendar.variableTime;
  const year = props.calendar.year;

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  useEffect(() => {
    const startDate = moment(year, 'YYYY').startOf('year');
    const endDate = moment(year, 'YYYY').endOf('year');
    const monthsInYear = {};

    while (startDate.isSameOrBefore(endDate, 'day')) {
      const monthKey = startDate.format('MMMM');
      if (!monthsInYear[monthKey]) {
        monthsInYear[monthKey] = {
          days: [],
          total: '00:00',
        };
      }

      monthsInYear[monthKey].days.push({
        date: startDate.format('YYYY-MM-DD'),
        hours: moment(startDate).isoWeekday() > 0 && moment(startDate).isoWeekday() < 6 ? null : '00:00',
      });

      startDate.add(1, 'day');
    }

    //Ordenar por fecha los meses
    Object.keys(monthsInYear).map((month) => {
      monthsInYear[month].days.sort((a, b) => {
        return moment(a.date).format('DD') - moment(b.date).format('DD');
      });
    });

    setData(monthsInYear);
  }, []);

  const addTime = (e) => {
    const month = moment(e.target.id).format('MMMM');
    const day = moment(e.target.id).format('DD') - 1;

    if (moment(e.target.id).day() > 0 && moment(e.target.id).day() < 6) {
      //Resta al total si ya hay horas en el día
      if (data[month].days[day].hours !== null) {
        let total = data[month].total.split(':');
        total[0] = parseInt(total[0]) - parseInt(data[month].days[day].hours.split(':')[0]);
        total[1] = parseInt(total[1]) - parseInt(data[month].days[day].hours.split(':')[1]);
        if (total[1] < 0) {
          total[0] = parseInt(total[0]) - 1;
          total[1] = parseInt(total[1]) + 60;
        }
        data[month].total = `${total[0].toString().padStart(2, '0')}:${total[1].toString().padStart(2, '0')}`;
      }

      //Añade el registro de horas al día
      data[month].days[day].hours = activeInput.time;
      data[month].days[day].color = activeInput.color;

      //Suma al total
      let total = data[month].total.split(':');
      total[0] = parseInt(total[0]) + parseInt(activeInput.time.split(':')[0]);
      total[1] = parseInt(total[1]) + parseInt(activeInput.time.split(':')[1]);
      if (total[1] > 59) {
        total[0] = parseInt(total[0]) + 1;
        total[1] = parseInt(total[1]) - 60;
      }
      data[month].total = `${total[0].toString().padStart(2, '0')}:${total[1].toString().padStart(2, '0')}`;

      document.getElementById(e.target.id).style.backgroundColor = activeInput.color;
      document.getElementById(e.target.id).style.color = 'white';

      setData({ ...data });
    }
  };

  const onConfirm = async () => {
    let status = true;
    Object.values(data).map((month) => {
      month.days.map((day) => {
        if (day.hours === null) {
          return (status = false);
        }
      });
    });

    if (status) {
      //Introduce en firestore /calendars
      await addDoc(collection(db, 'calendars'), {
        name: name,
        data: data,
        year: year,
      }).then((docRef) => {
        //Asigna el calendario a los usuarios
        (async () => {
          for (const user of users) {
            try {
              await setDoc(
                doc(db, 'users', user.value),
                {
                  calendario: {
                    [year]: {
                      id: docRef.id,
                      horas_variables: parseInt(variableTime),
                      asuntos_propios: 4,
                    },
                  },
                },
                { merge: true }
              );
            } catch (error) {
              console.error('Error adding document: ', error);
            }
          }
        })();
      });

      document.querySelector('body').style.overflow = 'auto';
      navigate(0);
    } else {
      alert('No se han introducido todas las horas');
    }
  };

  const showModal = () => {
    document.getElementById('workCalendar-modal').classList.toggle('disabled');
  };

  const hideModal = () => {
    document.getElementById('workCalendar-modal').classList.toggle('disabled');
  };

  return (
    <>
      <div className='d-flex gap-2' style={{ marginLeft: '5%', marginBottom: '40px' }}>
        <button className='btn btn-light shadow-sm' onClick={() => showModal()}>
          <i className='fa-solid fa-plus '></i>
        </button>
        {inputValue.map((input) => (
          <div key={input.color}>
            <button
              className='btn btn-default'
              style={{ border: `1px solid ${input.color}`, color: `${input.color}`, backgroundColor: `${input.color + '30'}` }}
              onClick={() => setActiveInput({ time: input.time, color: input.color })}
            >
              {input.time}
            </button>
          </div>
        ))}
        <button className='btn btn-outline-warning rounded-lg' onClick={() => setActiveInput({ time: '00:00', color: '#ffc107' })}>
          Festivo
        </button>
        <button
          className='btn btn-default rounded-lg'
          style={{ border: `1px solid #ff8800`, color: `#ff8800`, backgroundColor: `#ff880030` }}
          onClick={() => setActiveInput({ time: '00:00', color: '#ff8800' })}
        >
          Vacaciones
        </button>
      </div>
      <div className='calendar'>
        {Object.keys(data).map((month) => (
          <div key={month}>
            <h3>{month}</h3>
            <div className='month'>
              <div className='day-name'>Lun</div>
              <div className='day-name'>Mar</div>
              <div className='day-name'>Mie</div>
              <div className='day-name'>Jue</div>
              <div className='day-name'>Vie</div>
              <div className='day-name'>Sab</div>
              <div className='day-name'>Dom</div>

              {data[month].days.map((day, i) =>
                // si es el primer day
                i === 0 ? (
                  <div id={day.date} key={month + i} className={`day`} style={{ gridColumnStart: ` ${moment(day.date).isoWeekday()} ` }} onClick={(e) => addTime(e)}>
                    {moment(day.date).format('DD')}
                  </div>
                ) : (
                  <div id={day.date} key={month + i} className='day' onClick={(e) => addTime(e)}>
                    {moment(day.date).format('DD')}
                  </div>
                )
              )}
            </div>
            <div className='total'>
              <h5>Total: {data[month].total}</h5>
            </div>
          </div>
        ))}
      </div>

      <div className={'mt-4'} style={{ marginLeft: '35%' }}>
        <button className='btn btn-danger w-50 ml-25' onClick={() => onConfirm()}>
          Guardar
        </button>
      </div>

      <div className='modal disabled' id='workCalendar-modal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='exampleModalLabel'>
                Crear jornada
              </h1>
              <button type='button' className='btn-close' onClick={() => hideModal()}></button>
            </div>
            <div className='modal-body'>
              <form className='mt-2'>
                <div className='row gx-2'>
                  <div className='col-md-2 mb-3'>
                    <input type='color' className='form-control' value='#ffc107' disabled />
                  </div>
                  <div className='col-md-10 mb-3'>
                    <input type='text' className='form-control' value='Festivo' disabled />
                  </div>
                </div>
                <div className='row gx-2'>
                  <div className='col-md-2 mb-2'>
                    <input type='color' className='form-control' value='#ff8000' disabled />
                  </div>
                  <div className='col-md-10 mb-2'>
                    <input type='text' className='form-control' value='Vacaciones' disabled />
                  </div>
                </div>
                {inputValue.map((input, i) => (
                  <div className='row gx-2' key={input.color + i}>
                    <div className='col-md-2 my-2 '>
                      <input
                        type='color'
                        className='form-control'
                        defaultValue={input.color}
                        onChange={(e) => {
                          setInputValue([...inputValue.slice(0, i), { time: inputValue[i].time, color: e.target.value }, ...inputValue.slice(i + 1)]);
                        }}
                      />
                    </div>
                    <div className='col-md-10 my-2'>
                      <input
                        type='time'
                        className='form-control'
                        defaultValue={input.time}
                        min='05:00'
                        max='10:00'
                        onChange={(e) => {
                          setInputValue([...inputValue.slice(0, i), { time: e.target.value, color: inputValue[i].color }, ...inputValue.slice(i + 1)]);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </form>
              <div className='more'>
                <button type='button' className='btn btn-default mx-2' onClick={() => setInputValue([...inputValue, { time: '00:00', color: 'black' }])}>
                  <i className='fa-solid fa-plus'></i>
                </button>
                <button type='button' className='btn btn-default mx-2' onClick={() => setInputValue(inputValue.slice(0, inputValue.length - 1))}>
                  <i className='fa-solid fa-minus'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
