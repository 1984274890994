import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { userContext } from '../../context/UserContext';
import { collection, query, where, getDocs, documentId, doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { TimeControlCalendar } from '../../components/ui/TimeControlCalendar';
import { FetchTimeOut } from '../../functions/FetchTimeOut';
import { ShowToast } from '../../functions/ShowToast';
import './styles/TimeControl.css';

export const TimeControl = () => {
  const { userData } = useContext(userContext);

  const [connection, setConnection] = useState(true);
  const [complete, setComplete] = useState('first');
  const [projects, setProjects] = useState([]);
  const [totalHours, setTotalHours] = useState({ total: '', restante: '' });
  const [imputed, setImputed] = useState([]);
  //prettier-ignore
  const [inputs, setInputs] = useState([]);

  var user = userData.userSession;
  var records = userData.userRecords;
  const week = {
    start: moment().subtract(userData.unjustifiedWeeks, 'week').isoWeekday(1).format('YYYY-MM-DD'),
    end: moment().subtract(userData.unjustifiedWeeks, 'week').isoWeekday(6).format('YYYY-MM-DD'),
  };

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    if (parseInt(userData.unjustifiedWeeks) < 2) {
      document.querySelector('body').style.overflow = 'auto';
      navigate('/');
    }
  };

  // Chequea si el usuario a rellenado el control horario las dos semanas anteriore
  useEffect(() => {
    (async () => {
      const res = await FetchTimeOut(`${process.env.REACT_APP_SERVER}/status/`, 5000);
      if (res.status !== 200 || res.date !== moment().format('YYYY-MM-DD')) {
        setConnection(false);
        window.location.hash = 'no-back-button';
        window.location.hash = 'Again-No-back-button'; //chrome
        window.onhashchange = function () {
          window.location.hash = 'no-back-button';
        };
      } else {
        //Entra a chequear si esta completo a no ser que la semana sea 0
        if (userData.unjustifiedWeeks === 0) {
          setComplete(true);
        } else {
          await getDocs(collection(db, 'users', user.uid, 'partes')).then((documents) => {
            const partes = {};
            documents.docs.map((doc) => {
              partes[doc.id] = doc.data();
            });

            if (partes[moment(week.end).format('YYYY')] && partes[moment(week.end).format('YYYY')][moment(week.end).isoWeek()]) {
              setComplete(true);
            } else {
              setComplete(false);
              window.location.hash = 'no-back-button';
              window.location.hash = 'Again-No-back-button'; //chrome
              window.onhashchange = function () {
                window.location.hash = 'no-back-button';
              };
            }
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Calcula el total de horas trabajadas en la semana
  useEffect(() => {
    if (complete === 'first' || complete === true) return;
    (async () => {
      //console.log('entra total horas trabajadas');
      if (moment(week.start).format('MM') !== moment(week.end).format('MM') || moment().format('MM') !== moment(week.start).format('MM')) {
        const docs = await getDocs(query(collection(db, 'users', user.uid, 'registros'), where(documentId(), '==', moment(week.start).format('MMMM-YYYY'))));
        const data = docs.docs.map((doc) => doc.data())[0];
        // eslint-disable-next-line
        records = { ...records, ...data };
      }

      var h = 0;
      var min = 0;

      for (var m = moment(week.start); m.isBefore(week.end); m.add(1, 'days')) {
        //comprueba si hay registros en la fecha
        if (records[m.format('DD-MM-YYYY')]) {
          var recordsPerDay = Object.keys(records[m.format('DD-MM-YYYY')]).length;
          var totalAcumulado = records[m.format('DD-MM-YYYY')][recordsPerDay - 1].totalAcumulado;
          if (!totalAcumulado) {
            window.confirm('Tienes errores de fichaje el día ' + m.format('DD-MM-YYYY') + ' por favor revisa tus registros');
            navigate('/incidence', { state: { block: true } });
          }
          h += parseInt(totalAcumulado.split(':')[0]);
          min += parseInt(totalAcumulado.split(':')[1]);
        }
      }

      if (min > 60) {
        h = h + Math.trunc(min / 60);
        min = min % 60;
      }

      let total = h.toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0');

      // Calcula el desayuno
      let desayuno = (h * 3600 + min * 60) * 0.04;
      let h_desayuno = Math.trunc(desayuno / 3600);
      let m_desayuno = Math.trunc((desayuno % 3600) / 60);
      let total_desayuno = h_desayuno.toString().padStart(2, '0') + ':' + m_desayuno.toString().padStart(2, '0');

      // Calcula las horas restantes, contando el desayuno
      let restante = h * 3600 + min * 60 - desayuno;
      let h_restante = Math.trunc(restante / 3600);
      let m_restante = Math.trunc((restante % 3600) / 59);
      restante = h_restante.toString().padStart(2, '0') + ':' + m_restante.toString().padStart(2, '0');

      setTotalHours({ total: total, restante: restante });
      setImputed([{ id: 1, value: total_desayuno }]);
      setInputs([
        { id: 1, hours: total_desayuno, value: '000' + new Date().getFullYear().toString().slice(-2), phases: undefined, description: 'Almuerzo', locked: true },
        { id: 2, hours: '00:00', value: '', phases: undefined, description: '', locked: false },
      ]);
    })();
  }, [complete]);

  // Obtiene los proyectos
  useEffect(() => {
    if (complete === 'first' || complete === true) return;

    //console.log('entra obtener proyectos');
    getDocs(query(collection(db, 'projects'))).then((data) => {
      var arrProjects = [];
      data.forEach((doc) => {
        arrProjects.push({ id: doc.id, name: doc.data().nombre, fases: doc.data().fases });
      });
      setProjects(arrProjects);
    });
  }, [complete]);

  const subtractTotal = (e) => {
    if (e.target.value === '') return;

    if (imputed.find((item) => item.id === e.target.id)) {
      //console.log('existe');
      setImputed(imputed.map((item) => (item.id === e.target.id ? { id: e.target.id, value: e.target.value } : item)));
    } else {
      setImputed([...imputed, { id: e.target.id, value: e.target.value }]);
    }
  };

  useEffect(() => {
    if (complete === 'first' || complete === true) return;

    //console.log('entra restar horas trabajadas');

    var h = 0;
    var min = 0;

    imputed.forEach((item) => {
      h += parseInt(item.value.split(':')[0]);
      min += parseInt(item.value.split(':')[1]);
    });

    h = parseInt(totalHours.total.split(':')[0]) - h;
    min = parseInt(totalHours.total.split(':')[1]) - min;

    while (min < 0) {
      h = h - 1;
      min = 60 + min;
    }

    if (h < 0) {
      h = h + 1;
      if (h === 0) {
        min = (60 - min) * -1;
      } else {
        min = 60 - min;
      }
    }

    var result = h.toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0');

    if (imputed.length !== 0) {
      setTotalHours({ ...totalHours, restante: result });
    }
    // eslint-disable-next-line
  }, [imputed]);

  const getPhases = (e) => {
    const { id, value } = e.target;

    const proj_Phases = projects.find((item) => item.id === value).fases;
    if (proj_Phases) {
      inputs[id - 1].phases = proj_Phases;
      setInputs([...inputs]);
    } else {
      inputs[id - 1].phases = undefined;
      setInputs([...inputs]);
    }
  };

  const addInput = () => {
    setInputs([...inputs, { id: inputs.length + 1, hours: '00:00', value: '', phases: undefined, description: '', locked: false }]);
  };

  const delInput = () => {
    var inputValue = document.getElementById(inputs.length).value;
    var h = parseInt(totalHours.restante.split(':')[0]) + parseInt(inputValue.split(':')[0]);
    var min = parseInt(totalHours.restante.split(':')[1]) + parseInt(inputValue.split(':')[1]);

    if (min > 60) {
      h = h + 1;
      min = min - 60;
    }

    var result = h.toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0');

    setTotalHours({ ...totalHours, restante: result });
    setInputs(inputs.slice(0, -1));
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    var length = e.target.length - 1;

    var j = 0;
    var record;
    var error = false;

    for (var i = 0; j < length / 4; i += 4) {
      record = {
        PROYECTO: e.target[i + 1].value,
        EMPLEADO: userData.userSession.iniciales,
        DEPARTAMENTO: userData.userSession.departamento,
        SEMANA: moment(week.start).isoWeek(),
        F_INICIO: moment(week.start).format('YYYY/MM/DD'),
        F_FIN: moment(week.end).subtract(1, 'days').format('YYYY/MM/DD'),
        HORAS: e.target[i].value,
        FASE: e.target[i + 2].value,
        OBSERVACIONES: e.target[i + 3].value,
      };

      await fetch(`${process.env.REACT_APP_SERVER}/projectsHours/insert?apiKey=${process.env.REACT_APP_AQUA_APIKEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(record),
      })
        //eslint-disable-next-line
        .then((res) => res.json())
        //eslint-disable-next-line
        .then((data) => {
          if (data.status === 401 || data.status === 500) {
            error = data.status;
          }
        })
        //eslint-disable-next-line
        .catch((err) => {
          console.log(err);
          error = 500;
        });

      j++;
    }

    if (error === false) {
      //Introduce el parte en Aqua diferenciando si está de vacaciones o no
      const ref = await doc(db, 'users', user.uid, 'partes', moment(week.end, 'YYYY-MM-DD').format('YYYY'));

      let partes = {};

      if (user.estadoSesion === 'Vacaciones') {
        const lastRecordWeek = moment(week.start).isoWeek();
        const currentWeek = moment().subtract(1, 'week').isoWeek();

        for (let i = lastRecordWeek; i <= currentWeek; i++) {
          partes = { ...partes, [i]: 'OK' };
        }
      } else {
        const w = moment(week.start).isoWeek();
        partes = { [w]: 'OK' };
      }

      //Introduce parte en Firestore
      await setDoc(ref, partes, { merge: true });

      ShowToast('Parte rellenado correctamente', 'success');
      document.querySelector('body').style.overflow = 'auto';
      navigate('/');
    } else {
      alert('Error al rellenar el parte.\nError: ' + error);
      exit();
    }

    //Usar localstorage ( encriptar )
    //localStorage.setItem('partes', JSON.stringify({ ...JSON.parse(localStorage.getItem('partes')), [semana]: 'OK' }));
  };

  return (
    <div className='modal-route'>
      <div className='timeControl'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa-regular fa-pen-to-square'></i>Rellenar Control Horario
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          {window.innerWidth < 1000 ? (
            <div className='row '>
              <img src='static/media/mobile-error.png' alt='Error de conexión' className='connection-error mt-2' />
              <p className='text-center mt-4'>No es posible imputar horas desde el movil</p>
            </div>
          ) : connection === false ? (
            <div className='row '>
              <img src='static/media/network-error.png' alt='Error de conexión' className='connection-error mt-2' />
              <p className='text-center'>No tienes conexion con el servidor</p>
            </div>
          ) : complete === true ? (
            <div className='row '>
              <p>Tienes el control horario al día</p>
            </div>
          ) : (
            <div className='row '>
              <div className='col-md-12 mt-2 mb-4'>
                <span>
                  Horas pendientes: <strong className='mx-2'>{totalHours.restante}</strong>
                </span>{' '}
                <span className='float-end'>
                  Semana <strong>{moment(week.start).isoWeek()}</strong> del <strong> {moment(week.start).format('DD/MM/YYYY')} </strong> al{' '}
                  <strong> {moment(week.end).subtract(1, 'days').format('DD/MM/YYYY')} </strong>
                </span>
              </div>
              <div className='col-sm-12 col-md-3 col-xxl-2 mt-2 mb-4'>
                <TimeControlCalendar start={week.start} end={moment(week.end).subtract(1, 'day')} />
              </div>
              <div className='col-sm-12 col-md-9 col-xxl-10 mt mb-4'>
                <form onSubmit={(e) => onConfirm(e)}>
                  {inputs.map((input) => (
                    <div className='row mb-4' key={input.id}>
                      <div className='col-sm-12 col-md-2 col-xxl-1 mb-2'>
                        <label htmlFor='hours'>Horas :</label>
                        <input type='time' className='form-control' id={input.id} defaultValue={input.hours} onBlur={(e) => subtractTotal(e)} required disabled={input.locked} />
                      </div>
                      <div className='col-sm-12 col-md-4 col-xxl-3 mb-2'>
                        <label htmlFor='project'>Proyecto :</label>
                        <select className='form-select' id={input.id} defaultValue={input.value} required onChange={(e) => getPhases(e)} disabled={input.locked}>
                          {input.value ? (
                            <option value={input.value}>
                              {input.value} - GASTOS GENERALES {new Date().getFullYear()}
                            </option>
                          ) : (
                            <>
                              <option value='' disabled selected>
                                Seleccionar
                              </option>
                              {projects.map((project) => (
                                <option key={project.id} value={project.id}>
                                  {project.id} - {project.name}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                      <div className='col-sm-12 col-md-2 col-xxl-2 mb-2'>
                        <label htmlFor='fase'>Fase :</label>
                        {input.id === 1 ? (
                          <select className='form-select' id='fase' name={input.id} required disabled={input.locked}>
                            <option value='4'> 4 - ALMUERZOS Y REUNIONES INTERNAS </option>
                          </select>
                        ) : input.phases ? (
                          <select className='form-select' id='fase' name={input.id} required>
                            <option value='' disabled>
                              Seleccionar
                            </option>
                            {input.phases.map((phase) => (
                              <option key={phase.id} value={phase.id}>
                                {phase.id} - {phase.descripcion}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <select className='form-select' id='fase' disabled>
                            <option value=''> Seleccionar </option>
                          </select>
                        )}
                      </div>
                      <div className='col-sm-12 col-md-4 col-xxl-6'>
                        <label htmlFor='description'>Observaciones :</label>
                        <textarea type='text' className='form-control' id='description' defaultValue={input.description} rows='1' disabled={input.locked} />
                      </div>
                    </div>
                  ))}
                  {parseInt(totalHours.restante.split(':')[0]) === 0 && parseInt(totalHours.restante.split(':')[1]) === 0 && (
                    <div className='col-md-12'>
                      <button type='submit' className='btn btn-danger float-end'>
                        Confirmar CH
                      </button>
                    </div>
                  )}
                </form>
              </div>
              <div className='col-md-12 add-more'>
                <button className='btn btn-default' onClick={(event) => addInput(event)}>
                  <i className='fas fa-plus'></i> Añadir tramo
                </button>
                <button className='btn btn-default' onClick={(event) => delInput(event)}>
                  <i className='fas fa-minus'></i> Eliminar tramo
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
